const last = <T>(array: Array<T>): T => {
    if(array.length === 0) {
        throw new Error("List must have at least one element");
    }
    return array[array.length - 1];
}

const init = <T>(array: Array<T>): Array<T> => {
    if(array.length === 0) {
        throw new Error("List must have at least one element");
    }
    return array.slice(0, array.length - 1);
};
export {
    init,
    last,
}