import Player from './Player';
import Winds, { WindValues, forName as windForName } from './Winds';
import _ from 'lodash';

export enum EndType {
  Chombo = "chombo",
  Draw = "draw",
  Ron = "ron",
  Tsumo = "tsumo"
};

type _ScoreMap  = {
  [fu: number]: number[],
};
type _ScoringType = {
  east: {
    [EndType.Tsumo]: _ScoreMap,
    [EndType.Ron]: _ScoreMap,
  },
  noneast: {
    [EndType.Tsumo]: {
      [fu: number] : [number, number][],
    },
    [EndType.Ron]: _ScoreMap,
  },
  limit: number[],
  yakuman: number,
};

const scoring : _ScoringType = {
  east: {
    [EndType.Tsumo] : {
      20:[undefined,  700, 1300, 2600],
      25:[undefined, undefined, 1600, 3200],
      30:[ 500, 1000, 2000, 3900],
      40:[ 700, 1300, 2600, 4000],
      50:[ 800, 1600, 3200, 4000],
      60:[1000, 2000, 3900, 4000],
      70:[1200, 2300, 4000, 4000],
    },
    [EndType.Ron] : {
      25:[undefined, 2400, 4800, 9600],
      30:[1500, 2900, 5800, 11600],
      40:[2000, 3900, 7700, 12000],
      50:[2400, 4800, 9600, 12000],
      60:[2900, 5800, 11600, 12000],
      70:[3400, 6800, 12000, 12000],
    },
  },
  noneast: {
    [EndType.Tsumo]: {
      20:[undefined, [400, 700], [700, 1300], [1300, 2600]],
      25:[undefined, undefined, [800, 1600], [1600, 3200]],
      30:[[300, 500], [500, 1000], [1000, 2000], [2000, 3900]],
      40:[[400, 700], [700, 1300], [1300, 2600], [2000, 4000]],
      50:[[400, 800], [800, 1600], [1600, 3200], [2000, 4000]],
      60:[[500, 1000], [1000, 2000], [2000, 3900], [2000, 4000]],
      70:[[600, 1200], [1200, 2300], [2000, 4000], [2000, 4000]],
    },
    [EndType.Ron]: {
      25:[undefined, 1600, 3200, 6400],
      30:[1000, 2000, 3900, 7700],
      40:[1300, 2600, 5200, 8000],
      50:[1600, 3200, 6400, 8000],
      60:[2000, 3900, 7700, 8000],
      70:[2300, 4500, 8000, 8000],
    },
  },
  limit: [8000, 12000, 12000, 16000, 16000, 16000, 24000], // TODO: remember that 11+ is same, yakuman is specific to combo that score it
  yakuman: 32000,
};


const renderPayments = (winType: EndType, seatWind: Winds, fan: number, fu: number) => {
  const roundedFu = fu === 25 ? 25 : (Math.ceil(fu/10)*10);
  if(winType === EndType.Ron) {
    const payment = fan >= 5
      ? (scoring.limit[Math.min(fan, 11) - 5] * (seatWind === Winds.East ? 1.5 : 1))
      : (scoring[seatWind][winType][roundedFu] && scoring[seatWind][winType][roundedFu][fan - 1]);
    return payment === undefined ? "Illegal combination" :  `Discarder pays ${payment}`;
  }
  if(seatWind === Winds.East) {
    const payment = fan >= 5
      ? (scoring.limit[Math.min(fan, 11) - 5] * 1.5)
      : (scoring[seatWind][winType][roundedFu] && scoring[seatWind][winType][roundedFu][fan - 1]);
    return payment === undefined ? "Illegal combination" : `Everyone pays ${payment}`;
  }
  const payment = fan >= 5
    ? [(scoring.limit[Math.min(fan, 11) - 5] / 4), (scoring.limit[Math.min(fan, 11) - 5] / 4) * 2]
    : (scoring[seatWind][winType][roundedFu] && scoring[seatWind][winType][roundedFu][fan - 1]);
  if(payment === undefined) {
    return "Illegal combination";
  }
  const [othersPayment, eastPayment] = payment;
  return `East pays ${eastPayment}, everyone else pays ${othersPayment}`;
};

const windToJp = (wind: Winds) : string => {
  return {
    [Winds.East]: "東",
    [Winds.South]: "南",
    [Winds.West]: "西",
    [Winds.North]: "北",
  }[wind];
};

const winds = Object.values(Winds);

const windRightOf = (wind: Winds): Winds => {
  return winds[(winds.indexOf(wind) + 1) % 4];
};

const rotateWinds = (pivotWind: Winds): Winds[] => {
  const pivot = winds.indexOf(windRightOf(pivotWind));
  return winds.slice(pivot, winds.length).concat(winds.slice(0,pivot)) as Winds[];
};

const currentSeatWindOfInitialSeatWind = (gameData: GameData, initialSeating: Winds): Winds => {
  // TODO invariant checking on hand num between 1-4, winds being valid, gameData not having holes...
  const initialSeatingOffset = winds.indexOf(initialSeating);
  return winds[(initialSeatingOffset - gameData.activeRound.handNumber + 5) % 4];
};

const initialWindOfCurrentSeatWind = (gameData: GameData, currentSeating: Winds): Winds => {
  const currentSeatingOffset = winds.indexOf(currentSeating);
  return winds[(currentSeatingOffset + gameData.activeRound.handNumber - 1) % 4];
};

const playerWithCurrentSeatWind = (gameData: GameData, currentSeating: Winds): Player => {
  return gameData.players[initialWindOfCurrentSeatWind(gameData, currentSeating)];
};

const seatWindOfPlayerNamed = (gameData: GameData, playerName: string) : Winds => {
  const initialSeat = Object.entries(gameData.players).find(([k, v]) => v.name === playerName)[0] as Winds;
  return currentSeatWindOfInitialSeatWind(gameData, initialSeat);
};

const eastPaymentOptionsSorter = (lhs: number, rhs: number) : number => lhs - rhs;
const othersPaymentOptionsSorter: (lhs: [number, number], rhs: [number, number]) => number = ([l1, l2], [r1, r2]) => (l1 - r1) || (l2 - r2);
const eastTsumoPaymentOptions = [...new Set(Object.values(scoring.east[EndType.Tsumo]).flat().concat(scoring.limit.map((l) => l/2)).concat([scoring.yakuman/2]).filter((s) => s))].sort(eastPaymentOptionsSorter);
const othersTsumoPaymentOptions = [...new Set(Object.values(scoring.noneast[EndType.Tsumo]).flat().concat(scoring.limit.map((l) => [l/4, l/2])).concat([[scoring.yakuman/4, scoring.yakuman/2]]).sort().reduce((m, e) => m[m.length -1] && (m[m.length -1].join('') === (e && e.join(''))) ? m : m.concat([e]), []).filter((s) => s))].sort(othersPaymentOptionsSorter);
const eastRonPaymentOptions = [...new Set(Object.values(scoring.east[EndType.Ron]).flat().concat(scoring.limit.map((l) => l * 1.5)).concat([scoring.yakuman * 1.5]).filter((s) => s))].sort(eastPaymentOptionsSorter);
const othersRonPaymentOptions = [...new Set(Object.values(scoring.noneast[EndType.Ron]).flat().concat(scoring.limit).concat([scoring.yakuman]).filter((s) => s))].sort(eastPaymentOptionsSorter);

interface ActiveRound {
  prevalentWind: Winds,
  handNumber: number,
  bonusCounter: number,
  riichiBets: number,
}

type Players  = {
  [wind in Winds]: Player
}

interface GameData {
  activeRound: ActiveRound,
  players: Players,
}

// should stuff that does not push history (rename, undo) be here too?
enum GameEventType {
   Ron,
   Tsumo,
   Draw,
   Chombo,
   Riichi,
}

interface RonEvent {
  type: GameEventType.Ron,
  winningPlayersAndPayments: [Winds, number][],
  discardingPlayer: Winds,
};

interface TsumoEvent {
  type: GameEventType.Tsumo,
  winningPlayer: Winds,
  payments: number[],
};

interface ChomboEvent {
  type: GameEventType.Chombo,
  offendingPlayer: Winds,
};

interface DrawEvent {
  type: GameEventType.Draw,
  tenpaiPlayers: Winds[],
};

interface RiichiEvent {
  type: GameEventType.Riichi,
  declaringPlayer: Winds,
};

type GameEvent = RonEvent | TsumoEvent | ChomboEvent | DrawEvent | RiichiEvent;

const newGame = (eastName: string, southName: string, westName: string, northName: string) : GameData => ({
  activeRound : {
    prevalentWind: Winds.East,
    handNumber: 1,
    bonusCounter: 0,
    riichiBets: 0,
  },
  // initial seatings
  players: {
    [Winds.East]:  { name: eastName, score: 25000, isRiichi: false, seatWind: Winds.East },
    [Winds.South]: { name: southName, score: 25000, isRiichi: false, seatWind: Winds.South },
    [Winds.West]:  { name: westName, score: 25000, isRiichi: false, seatWind: Winds.West },
    [Winds.North]: { name: northName, score: 25000, isRiichi: false, seatWind: Winds.North },
  },
});

// TODO: the way an updated object is returned is all inconsistent across the next functions. It's important that the top-level is a new object otherwise
// react doesn't recognise the state change but probably using assign 1 level deep is better.
// also maybe consider returning a bunch of curriable fns that can be used in a function-style setState ? although the logic would become rather tied to knowing it's used in react
const nextRound = (gameData: GameData, endType: EndType, winnerOrTenpai?: Winds[] | Winds) : GameData => {
  // TODO: validate winnerOrTenpai, must be a seat wind for ron and tsumo or an ary for draw or undef/null for chombo
  for(const wind of winds) {
    gameData.players[wind].isRiichi = false;
  }
  if(endType === EndType.Chombo) {
    return _.cloneDeep(gameData);
  }
  const isEastWinOrTenpai = (winnerOrTenpai === Winds.East || (winnerOrTenpai.includes && winnerOrTenpai.includes(Winds.East)));
  const isEndGame = (gameData.activeRound.prevalentWind === Winds.South) && (gameData.activeRound.handNumber === 4) && !isEastWinOrTenpai;
  if(isEndGame) {
    return Object.assign(_.cloneDeep(gameData), {
      activeRound: {
        prevalentWind: gameData.activeRound.prevalentWind,
        handNumber: gameData.activeRound.handNumber,
        bonusCounter: 0,
        riichiBets: 0,
      }
    });
  }
  const newData = Object.assign(_.cloneDeep(gameData), {
    activeRound: {
      prevalentWind: (gameData.activeRound.prevalentWind === Winds.East && gameData.activeRound.handNumber === 4 && !isEastWinOrTenpai) ? Winds.South : gameData.activeRound.prevalentWind,
      handNumber: isEastWinOrTenpai ? gameData.activeRound.handNumber : (gameData.activeRound.handNumber % 4 + 1),
      bonusCounter: (isEastWinOrTenpai || endType === EndType.Draw) ? gameData.activeRound.bonusCounter + 1 : 0,
      riichiBets: [EndType.Draw, EndType.Draw].includes(endType) ? gameData.activeRound.riichiBets : 0,
    }
  });
  if(isEastWinOrTenpai) {
    return newData;
  }
  Object.entries(newData.players).forEach(([initialWind, player]) => (player as Player).seatWind = currentSeatWindOfInitialSeatWind(newData, windForName(initialWind as WindValues)));
  return newData;
};

const toggleRiichi = (gameData: GameData, initialWind: string) : GameData => {
  const updatedPlayers = _.cloneDeep(gameData.players);
  updatedPlayers[initialWind].isRiichi = !updatedPlayers[initialWind].isRiichi;
  updatedPlayers[initialWind].score += updatedPlayers[initialWind].isRiichi ? -1000 : 1000;
  const updatedActiveRound = _.cloneDeep(gameData.activeRound);
  updatedActiveRound.riichiBets += updatedPlayers[initialWind].isRiichi ? 1 : -1;
  // TODO: invariant test on riichiBets not going negative
  return {
    activeRound: updatedActiveRound,
    players: updatedPlayers,
  };
};

const renamePlayer = (gameData: GameData, initialWind: string, newName: string) : GameData => {
  const updatedPlayers = Object.assign({}, gameData.players);
  updatedPlayers[initialWind].name = newName;
  return {
    activeRound: gameData.activeRound,
    players: updatedPlayers,
  };
};

const isRiichi = (gameData: GameData, initialWind: string) : boolean => gameData.players[initialWind].riichi;

const dispatch = (gameData: GameData, event: GameEvent) : GameData => {
  switch (event.type) {
    case GameEventType.Ron:
      return ron(gameData, event.winningPlayersAndPayments, event.discardingPlayer);
    case GameEventType.Tsumo:
      return tsumo(gameData, event.winningPlayer, event.payments);
    case GameEventType.Draw:
      return draw(gameData, event.tenpaiPlayers);
    case GameEventType.Chombo:
      return chombo(gameData, event.offendingPlayer);
    case GameEventType.Riichi:
      return toggleRiichi(gameData, event.declaringPlayer);
  };
};

const chombo = (gameData: GameData, chomboPlayer: Winds) : GameData => {
  const chomboWind = currentSeatWindOfInitialSeatWind(gameData, chomboPlayer);
  const newData = nextRound(gameData, EndType.Chombo);
  if(chomboWind === Winds.East) {
    for(const wind of winds) {
      newData.players[wind].score += (wind === chomboPlayer ? -12000 : 4000);
      newData.players[wind].score += gameData.players[wind].isRiichi ? 1000 : 0; // riichi bets are returned to respective players
      newData.activeRound.riichiBets -= gameData.players[wind].isRiichi ? 1 : 0; // TODO weird but because chombo is the only case where we need a distinction between bets already on the table and placed in this round, decrement here instead of handling alongside the hand reset
    }
  } else {
    for(const wind of winds) {
      newData.players[wind].score += chomboPlayer === wind
        ? -8000
        : currentSeatWindOfInitialSeatWind(gameData, wind) === Winds.East
          ? 4000
          : 2000;
    }
  }
  return newData;
};

const draw = (gameData: GameData, tenpaiPlayers: Winds[]) : GameData => {
  const tenpaiSeats = tenpaiPlayers.map((p) => currentSeatWindOfInitialSeatWind(gameData, p));
  const newData = nextRound(gameData, EndType.Draw, tenpaiSeats);
  if(tenpaiPlayers.length && (tenpaiPlayers.length < 4)) {
    for(const wind of winds) {
      newData.players[wind].score += (tenpaiPlayers.includes(wind) ? (3000 / tenpaiPlayers.length) : -(3000 / (4 - tenpaiPlayers.length)));
    }
  }
  return newData;
};

const ron = (gameData: GameData, winningPairs: [Winds, number][], discardingPlayer: Winds) : GameData => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [winningPlayers, _payments] = [winningPairs.map(x => x[0]), winningPairs.map(x => x[1])];
  const winningSeats = winningPairs.map(([winner, _payment]) => currentSeatWindOfInitialSeatWind(gameData, winner));
  const sortedWinds = rotateWinds(currentSeatWindOfInitialSeatWind(gameData, discardingPlayer));
  const firstWinningPlayerFromRight = sortedWinds[winningPlayers.map((x) => sortedWinds.indexOf(x))[0]];
  const newData = nextRound(gameData, EndType.Ron, winningSeats);
  winningPairs.forEach(([winner, payment]) => {
    const riichiBonus = (winner === firstWinningPlayerFromRight) ? (gameData.activeRound.riichiBets * 1000) : 0;
    newData.players[winner].score += (payment + (300 * gameData.activeRound.bonusCounter) + riichiBonus);
    newData.players[discardingPlayer].score -= (payment + (300 * gameData.activeRound.bonusCounter));
  });
  return newData;
};
const tsumo = (gameData: GameData, winningPlayer: Winds, payments: number[]) : GameData => {
  const winningSeat = currentSeatWindOfInitialSeatWind(gameData, winningPlayer);
  const eastTsumo = (winningSeat === Winds.East);
  const newData = _.cloneDeep(gameData);
  if(eastTsumo) {
    playerWithCurrentSeatWind(newData, Winds.East).score += (payments.reduce((m, e) => m + e, 0) + (300 * gameData.activeRound.bonusCounter) + gameData.activeRound.riichiBets * 1000);
    for(const wind of [Winds.South, Winds.West, Winds.North]) {
      playerWithCurrentSeatWind(newData, wind).score -= (payments[0] + (100 * gameData.activeRound.bonusCounter));
    }
  } else {
    playerWithCurrentSeatWind(newData, Winds.East).score -= (payments[0] + (100 * gameData.activeRound.bonusCounter));
    for(const wind of [Winds.South, Winds.West, Winds.North]) {
      if(wind === winningSeat) {
        playerWithCurrentSeatWind(newData, wind).score += (payments.reduce((m, e) => m + e, 0) + (300 * gameData.activeRound.bonusCounter) + gameData.activeRound.riichiBets * 1000);
      } else {
        playerWithCurrentSeatWind(newData, wind).score -= (payments[1] + (100 * gameData.activeRound.bonusCounter));
      }
    }
  }
  return nextRound(newData, EndType.Tsumo, winningSeat);
};

const score = (gameData: GameData) : number[] => {
  return winds.map((w) => gameData.players[w].score);
}

export type {
  ActiveRound,
  GameData,
  RonEvent,
  TsumoEvent,
  ChomboEvent,
  DrawEvent,
  RiichiEvent,
  GameEvent,
}

export {
  chombo,
  draw,
  ron,
  tsumo,
  newGame,
  winds,
  windRightOf,
  rotateWinds,
  windToJp,
  eastTsumoPaymentOptions,
  othersTsumoPaymentOptions,
  eastRonPaymentOptions,
  othersRonPaymentOptions,
  renderPayments,
  currentSeatWindOfInitialSeatWind,
  playerWithCurrentSeatWind,
  initialWindOfCurrentSeatWind,
  seatWindOfPlayerNamed,
  nextRound,
  toggleRiichi,
  isRiichi,
  renamePlayer,
  dispatch,
  score,
  GameEventType,
};