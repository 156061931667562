import {
    GameData,
    currentSeatWindOfInitialSeatWind
} from './GameData';
import Winds from './Winds';


type GameSaveData = GameSaveDataV0 | GameSaveDataV1;

type GameSaveDataV0 = GameData;
interface GameSaveDataV1 {
    version: 1,
    data: [GameData[], boolean],
};

const save = (storableState: [GameData[], boolean]): GameSaveDataV1 => {
    return {
        version: 1,
        data: storableState,
    };
};

// TODO this works but I think it's actually a terrible design - a storage higher-order hook would be so much better, with generics
// something like: useStorage<T>(otherHook: fn(??): U, extractor: fn(U):VersionedArmor<T>): T
const LocalStorageGameRepository = {
    store: (data: GameSaveDataV1) => {
        localStorage.setItem("game-data", JSON.stringify(data));
    },
    load: (): GameSaveDataV1 | undefined => {
        const existingGameData: GameSaveData | undefined = JSON.parse(localStorage.getItem("game-data"));
        if(existingGameData) {
            return migrate(existingGameData);
        }
        return undefined;
    },
}

const migrate = (data: GameSaveData): GameSaveDataV1 | undefined => {
    if(!("version" in data)) {
        if(("players" in data) && ("activeRound" in data)) {
            data.players.east.seatWind = currentSeatWindOfInitialSeatWind(data, Winds.East);
            data.players.south.seatWind = currentSeatWindOfInitialSeatWind(data, Winds.South);
            data.players.west.seatWind = currentSeatWindOfInitialSeatWind(data, Winds.West);
            data.players.north.seatWind = currentSeatWindOfInitialSeatWind(data, Winds.North);
            return {
                version: 1,
                data: [[data], false],
            };
        }
        return undefined;
    }
    switch (data.version) {
        case 1: return data;
    };
}

export {
    LocalStorageGameRepository,
    save,
};