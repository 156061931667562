import React, { useState, /* useEffect */ } from 'react';
// import iconResolver from './Icons'
import {
  windToJp,
  eastTsumoPaymentOptions,
  othersTsumoPaymentOptions,
  eastRonPaymentOptions,
  othersRonPaymentOptions,
  renderPayments,
} from './GameData';
import {
  useGame,
} from './Game';
import {
  save,
  LocalStorageGameRepository,
} from './Storage';
import './App.css';

// const urlFor = (endpoint) => ["https://ron.gg/api/v0", endpoint].join('/');

const Score = () => {
  const [scoringData, setScoringData] = useState({
    winType: "tsumo",
    seatWind: "east",
    fan: 1,
    fu: 30,
  });

  return (
    <div>
      <div className="btn-group btn-group-toggle">
          <label className={"btn btn-primary btn-lg" + (scoringData.winType === "tsumo" ? " active" : "")}>
              <input type="radio" name="win-type" value="tsumo" defaultChecked={scoringData.winType === "tsumo"}
                onChange={(ev) => setScoringData(Object.assign({}, scoringData, {winType: ev.target.value}))}
              />
              Tsumo
          </label>
          <label className={"btn btn-primary btn-lg" + (scoringData.winType === "ron" ? " active" : "")}>
              <input type="radio" name="win-type" value="ron"
                onChange={(ev) => setScoringData(Object.assign({}, scoringData, {winType: ev.target.value}))}
              /> Ron
          </label>
      </div>
      <div className="btn-group btn-group-toggle">
          <label className={"btn btn-primary btn-lg" + (scoringData.seatWind === "east" ? " active" : "")}>
              <input type="radio" name="seat-wind" value="east" defaultChecked
                onChange={(ev) => setScoringData(Object.assign({}, scoringData, {seatWind: ev.target.value}))}
              /> East
          </label>
          <label className={"btn btn-primary btn-lg" + (scoringData.seatWind === "noneast" ? " active" : "")}>
              <input type="radio" name="seat-wind" value="noneast"
                onChange={(ev) => setScoringData(Object.assign({}, scoringData, {seatWind: ev.target.value}))}
              /> South, West, North
          </label>
      </div>
      <div className="input-group input-group-prepend">
          <div className="input-group mb-3">
              <div className="input-group-prepend">
                  <span className="input-group-text">Fan</span>
              </div>
              <input name="fan" type="number" className="form-control" min="1" max="11" value={scoringData.fan} aria-describedby="fan" 
                onChange={(ev) => setScoringData(Object.assign({}, scoringData, {fan: +ev.target.value}))}
              />
          </div>
      </div>
      <div className="input-group input-group-prepend">
          <div className="input-group mb-3">
              <div className="input-group-prepend">
                  <span className="input-group-text">Fu</span>
              </div>
              <input name="fu" type="number" className="form-control" min="20" max="70" value={scoringData.fu} aria-describedby="fu" 
                onChange={(ev) => setScoringData(Object.assign({}, scoringData, {fu: +ev.target.value}))}
              />
          </div>
      </div>
      <div className="score-output">
          <h1>{renderPayments(scoringData.winType, scoringData.seatWind, scoringData.fan, scoringData.fu)}</h1>
      </div>
    </div>
    );
}

// const Achievement = ({name, description, flavour_text: flavourText}) => (
//   <li className="media achievement">
//     <img src={iconResolver(name)} className="img-rounded icon mr-3" alt={name} />
//     <div className="media-body">
//       <h5 className="mt-0">{name}</h5>
//       <p className="description">{description}</p>
//       {flavourText && (<p className="flavour-text">{flavourText}</p>)}
//     </div>
//   </li>);

// const Achievements = () => {
//   const [achievements, setAchievements] = useState([]);
//   useEffect(() => {
//     fetch(urlFor('achievements'))
//         .then(result => result.json())
//         .then((data) => {
//             setAchievements(data);
//         });
//   }, []);
//   return (
//     <ul className="list-unstyled">
//       {achievements.map(achievement => (<Achievement { ...achievement } />))}
//     </ul>);
// }

const Links = () => (
  <div>
    <ul className="list-group">
      <li className="list-group-item">
          <a href="http://mahjong-europe.org/portal/index.php?option=com_content&view=article&id=30&Itemid=166" className="list-group-item">
              <span className="icon i16 ema"></span>RCR Rules
          </a>
      </li>
      <li className="list-group-item">
          <a href="https://github.com/caligin/netronner-ui" className="list-group-item">
              <span className="icon i16 octocat"></span>Github
          </a>
      </li>
  </ul>
  </div>
  );

const PlayerScore = ({seatWind, name, score, colour, highlight, riichiToggled, onClick, onRiichi, editable, onNameEdit}) => {
  // TODO validate highlight, must be a color str
  const [editing, setEditing] = useState(false);
  const [localName, setLocalName] = useState(name);
  return (
    <div className={"media player-score" + (highlight ? ` highlight-${highlight}` :"")} onClick={onClick}>
      <span className={`player-score-texticon ${colour}`}>{windToJp(seatWind)}</span>
      {
          editing ? (
            <div className="form-row">
              <input className="col-9" type="text" value={localName} onChange={(e) => setLocalName(e.target.value)}></input>
              <button className="col-3 btn btn-success btn-sm" onClick={() => {setEditing(false); onNameEdit(localName); }}>Done</button>
            </div>
          ) : (
            <div className="media-body">
              <h5 className={"mt-0" + (editable ? " editable" : "")} onClick={() => editable && setEditing(true)}>{name}</h5>
              <p>{score}</p>
            </div>
          )
      }
      {
          !editing && (
            <button className={"btn btn-success btn-sm" + (riichiToggled ? "" : " disabled")} onClick={onRiichi}>Riichi</button>
          )
      }
    </div>
  );
};

const ActiveRound = ({prevalentWind, handNumber, bonusCounter, riichiBets}) => {
  return (
    <header>
      <span className="round-indicator">{windToJp(prevalentWind)}</span><h1 className="hand-indicator">{handNumber}</h1>{bonusCounter ? `, bonus ${bonusCounter}` : ""}{riichiBets ? `, ${riichiBets} Riichi bets` : ""}
    </header>
  );
};

const playerColours = [
  "lightblue",
  "gold",
  "magenta",
  "lightsalmon"
];

const GameScoringHome = ({gameState, controls, navigate}) => {
  const {riichi, rename, newGame, undo} = controls;
  const [newGameConfirmation, setNewGameConfirmation] = useState(false);
  return (
    <div>
      <ActiveRound {...gameState} />
      {
        gameState.players.map((player, i) => (
          <PlayerScore
            key={i}
            seatWind={player.seatWind}
            name={player.name}
            score={player.score}
            colour={playerColours[i]}
            riichiToggled={player.isRiichi}
            editable="true"
            onNameEdit={(newName) => rename(player, newName)}
            onRiichi={() => riichi(player)} />
        ))
      }
      <div className="row">
        <button type="button" className="col btn btn-success" onClick={() => navigate("tsumo")}>Tsumo</button>
        <button type="button" className="col btn btn-success" onClick={() => navigate("ron")}>Ron</button>
        <div className="w-100"></div>
        <button type="button" className="col btn btn-secondary" onClick={() => navigate("draw")}>Draw</button>
        <button type="button" className="col btn btn-secondary" onClick={() => navigate("chombo")}>Chombo</button>
      </div>
      <div className="row">
        &nbsp;
      </div>
      <div className="row">
        <button type="button" className="col col-4 btn btn-dark btn-secondary" disabled={!gameState.canUndo} onClick={() => undo()}>⟲ Undo</button>
      </div>
      {
        newGameConfirmation
        ? (
          <div className="row">
            <button type="button" className="col col-4 btn btn-warning" onClick={() => { setNewGameConfirmation(false); newGame(true); }}>Confirm</button>
            <button type="button" className="col col-4 btn btn-danger" onClick={() => { setNewGameConfirmation(false); newGame(false); }}>Also clear names</button>
            <button type="button" className="col col-4 btn btn-secondary" onClick={() => { setNewGameConfirmation(false);}}>Cancel</button>
          </div>
        ) : (
          <div className="row">
            <button type="button" className="col col-4 btn btn-dark btn-secondary" onClick={() => setNewGameConfirmation(true)}>New game</button>
          </div>
        )
      }
    </div>
  );
}

const GameScoringEnd = ({gameState, controls, navigate}) => {
  const {undo, newGame} = controls;
  return (
    <div>
      <ActiveRound {...gameState} />
      {
        gameState.players.map((player, i) => (
          <PlayerScore
            key={i}
            seatWind={player.seatWind}
            name={player.name}
            score={player.score}
            colour={playerColours[i]}
            riichiToggled={false}
            />
        ))
      }
      <div className="row">
        <button type="button" className="col col-4 btn btn-dark btn-secondary" disabled={!gameState.canUndo} onClick={() => undo()}>⟲ Undo</button>
      </div>
      <div className="row">
        <button type="button" className="col btn btn-success" onClick={() => {newGame(false); navigate("home");}}>New Game</button>
      </div>
    </div>
  );
}

const GameScoringTsumo = ({gameState, controls, navigate}) => {
  const {riichi, tsumo} = controls;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [payments, setPayments] = useState([0, 0, 0]);
  const eastTsumoPaymentOptionsFormatted = eastTsumoPaymentOptions.map((o) => ({v:[o,o,o].join(','), t:`${o} each`}));
  const othersTsumoPaymentOptionsFormatted = othersTsumoPaymentOptions.map(([o, e]) => ({v: [e,o,o].join(','), t:[e, o].join("/")}));

  const eastTsumo = ((selectedIndex !== null) && gameState.players[selectedIndex].seatWind === "east");
  const scoreOptions = (selectedIndex === null)
    ? [{v:"",t:"Select winning player"}]
    : eastTsumo
      ? eastTsumoPaymentOptionsFormatted
      : othersTsumoPaymentOptionsFormatted;
  const applyTsumo = () => {
    tsumo(gameState.players[selectedIndex], payments);
    navigate("home");
  };
  const selectPlayer = (index) => {
    setSelectedIndex(index);
    // handled here because otherwise the select redraws but doesn't fire onChange and the state stays the same. is there a way to hook on a redraw?
    const newTsumoIsEast = (gameState.players[index].seatWind === "east");
    setPayments((newTsumoIsEast ? eastTsumoPaymentOptionsFormatted[0] : othersTsumoPaymentOptionsFormatted[0]).v.split(',').map((p) => p - 0));
  }
  return (
    <div>
      <ActiveRound {...gameState} />
      {
        gameState.players.map((player, i) => (
          <PlayerScore
            key={i}
            seatWind={player.seatWind}
            name={player.name}
            score={player.score}
            colour={playerColours[i]}
            highlight={(i === selectedIndex) && "green"}
            onClick={() => selectPlayer(i)}
            riichiToggled={player.isRiichi}
            onRiichi={() => riichi(player)} />
        ))
      }
      <div className="row">
        <select className="col" name="score" disabled={selectedIndex === null} onChange={(e) => setPayments(e.target.value.split(',').map((p) => p - 0))}>
          {scoreOptions.map((o) => (<option key={o.v} value={o.v}>{o.t}</option>))}
        </select>
      </div>
      <div className="row">
        <button type="button" className="col btn btn-success" onClick={applyTsumo} disabled={selectedIndex === null}>Confirm Tsumo</button>
      </div>
      <div className="row">
        <button type="button" className="col btn btn-secondary" onClick={() => navigate("home")}>Cancel</button>
      </div>
    </div>
  );
}

const GameScoringRon = ({gameState, controls, navigate}) => {
  // WIP11FIXME: multiron does wrong things with payments, like uses the third to pay all players
  const {riichi, ron} = controls;
  const [selectedIndexes, setSelectedIndexes] = useState([]);

  const [payments, setPayments] = useState([0, 0, 0, 0]);
  const makeSetPaymentForPlayer = (index) => (event) => setPayments(payments.map((p, i) => i === index ? event.target.value - 0 : p));

  const eastPaymentOptionsFormatted = eastRonPaymentOptions.map((o) => ({v:o, t:o}));
  const othersPaymentOptionsFormatted = othersRonPaymentOptions.map((o) => ({v:o, t:o}));
  const renderPaymentOptions = (player) =>
    player.seatWind === "east"
    ? eastPaymentOptionsFormatted
    : othersPaymentOptionsFormatted;
  const [noSelection, discarderNotSelected, singleRon, doubleRon, tripleRon] = [0, 1, 2, 3, 4].map(selections => selectedIndexes.length === selections);
  if(singleRon) {
    // FIXME: bogus if to use the var, with current versions there seems to be no way to suppress the no-unused-var on the line above
  }

  const winningIndexes = selectedIndexes.slice(2).concat(selectedIndexes[0]);
  const selectedPayments = winningIndexes.map(index => payments[index]);
  const allPaymentsSelected = selectedPayments.every(payment => payment !== 0);

  const applyRon = () => {
    if(selectedIndexes.length < 2) {
      throw new Error("UNREACHABLE: Invalid selection");
    }
    const discardingPlayer = gameState.players[selectedIndexes[1]];
    const winningPlayersAndPayments = winningIndexes.map(index => [gameState.players[index], payments[index]]);
    ron(winningPlayersAndPayments, discardingPlayer);
    navigate("home");
  };

  const selectPlayer = (index) => {
    if(selectedIndexes[selectedIndexes.length - 1] === index) {
      // reset payment as by default how's it done now the combobox shows the "select x" option, that would be 0
      setPayments(payments.map((p, i) => i === index ? 0 : p));
      setSelectedIndexes(selectedIndexes.slice(0, selectedIndexes.length - 1));
    } else {
      if(selectedIndexes.includes(index)) {
        return;
      }
      if(selectedIndexes.length !== 4) {
        setSelectedIndexes([...selectedIndexes, index]);
      }
    }
  }
  const resolveSelection = (index) => {
    // TODO make the latest more shiny to highlight that it's the "poppable" one?
    if(!selectedIndexes.includes(index)) {
      return false;
    }
    if(selectedIndexes[1] === index) {
      return "grey";
    }
    return "green";
  };

  return (
    <div>
      <ActiveRound {...gameState} />
      {
        gameState.players.map((player, i) => (
          <PlayerScore
            key={i}
            seatWind={player.seatWind}
            name={player.name}
            score={player.score}
            colour={playerColours[i]}
            highlight={resolveSelection(i)}
            onClick={() => selectPlayer(i)}
            riichiToggled={player.isRiichi}
            onRiichi={() => riichi(player)} />
        ))
      }
      {
        noSelection && (
          <div className="row">
            <p>Select winner</p>
          </div>
        )
      }
      {
        !noSelection && (
          <div className="row">
            <select className="col" name="score" disabled={noSelection} onChange={makeSetPaymentForPlayer(selectedIndexes[0])}>
              <option value="0">Payment to {gameState.players[selectedIndexes[0]].seatWind}</option>
              {renderPaymentOptions(gameState.players[selectedIndexes[0]]).map((o) => (<option key={o.v} value={o.v}>{o.t}</option>))}
            </select>
          </div>
        )
      }
      {
        (doubleRon || tripleRon) && (
          <div className="row">
              <select className="col" name="score" onChange={makeSetPaymentForPlayer(selectedIndexes[2])}>
                <option value="0">Payment to {gameState.players[selectedIndexes[2]].seatWind}</option>
                {renderPaymentOptions(gameState.players[selectedIndexes[2]]).map((o) => (<option key={o.v} value={o.v}>{o.t}</option>))}
              </select>
          </div>
        )
      }
      {
        tripleRon && (
          <div className="row">
              <select className="col" name="score" onChange={makeSetPaymentForPlayer(selectedIndexes[3])}>
                <option value="0">Payment to {gameState.players[selectedIndexes[3]].seatWind}</option>
                {renderPaymentOptions(gameState.players[selectedIndexes[3]]).map((o) => (<option key={o.v} value={o.v}>{o.t}</option>))}
              </select>
          </div>
        )
      }
      <div className="row">
        <button type="button" className="col btn btn-success" onClick={applyRon} disabled={noSelection || discarderNotSelected || !allPaymentsSelected}>Confirm Ron</button>
      </div>
      <div className="row">
        <button type="button" className="col btn btn-secondary" onClick={() => navigate("home")}>Cancel</button>
      </div>
    </div>
  );
}

const GameScoringDraw = ({gameState, controls, navigate}) => {
  const {riichi, draw} = controls;
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const applyDraw = () => {
    draw(selectedIndexes.map(index => gameState.players[index]));

    navigate("home");
  };
  const selectPlayer = (index) => {
    if(selectedIndexes.includes(index)) {
      setSelectedIndexes(selectedIndexes.filter((i) => i !== index));
    } else {
      setSelectedIndexes([index, ...selectedIndexes]);
    }
  }
  const resolveSelection = (index) => {
    if(selectedIndexes.includes(index)) {
      return "green";
    }
    return false;
  };
  return (
    <div>
      <ActiveRound {...gameState} />
      {
        gameState.players.map((player, i) => (
          <PlayerScore
            key={i}
            seatWind={player.seatWind}
            name={player.name}
            score={player.score}
            colour={playerColours[i]}
            highlight={resolveSelection(i)}
            onClick={() => selectPlayer(i)}
            riichiToggled={player.isRiichi}
            onRiichi={() => riichi(player)} />
        ))
      }
      <div className="row">
        <p>Select tenpai players</p>
      </div>
      <div className="row">
        <button type="button" className="col btn btn-success" onClick={applyDraw}>Confirm Draw</button>
      </div>
      <div className="row">
        <button type="button" className="col btn btn-secondary" onClick={() => navigate("home")}>Cancel</button>
      </div>
    </div>
  );
}

const GameScoringChombo = ({gameState, controls, navigate}) => {
  const {riichi, chombo} = controls;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const applyChombo = () => {
    chombo(gameState.players[selectedIndex]);
    navigate("home");
  };
  const resolveSelection = (index) => {
    if(selectedIndex === index) {
      return "green";
    }
    return false;
  };
  return (
    <div>
      <ActiveRound {...gameState} />
      {
        gameState.players.map((player, i) => (
          <PlayerScore
            key={i}
            seatWind={player.seatWind}
            name={player.name}
            score={player.score}
            colour={playerColours[i]}
            highlight={resolveSelection(i)}
            onClick={() => setSelectedIndex(i)}
            riichiToggled={player.isRiichi}
            onRiichi={() => riichi(player)} />
        ))
      }
      <div className="row">
        <p>Select chombo player</p>
      </div>
      <div className="row">
        <button type="button" className="col btn btn-success" onClick={applyChombo} disabled={selectedIndex === null}>Confirm Chombo</button>
      </div>
      <div className="row">
        <button type="button" className="col btn btn-secondary" onClick={() => navigate("home")}>Cancel</button>
      </div>
    </div>
  );
}


const GameScoring = () => {
  const [flowScreen, setFlowScreen] = useState("home");
  const existingGameData = LocalStorageGameRepository.load();
  const [gameState, controls, data] = useGame(existingGameData && existingGameData.data);
  LocalStorageGameRepository.store(save(data));

  // TODO: navigate should have some validation
  return (
    <div className="game-scoring">
      {flowScreen === "home" && (gameState.isOver ?
        (<GameScoringEnd gameState={gameState} controls={controls} navigate={setFlowScreen} />) :
        (<GameScoringHome gameState={gameState} controls={controls} navigate={setFlowScreen} />))
      }
      {flowScreen === "tsumo" && (<GameScoringTsumo gameState={gameState} controls={controls} navigate={setFlowScreen} />)}
      {flowScreen === "ron" && (<GameScoringRon gameState={gameState} controls={controls} navigate={setFlowScreen} />)}
      {flowScreen === "draw" && (<GameScoringDraw gameState={gameState} controls={controls} navigate={setFlowScreen} />)}
      {flowScreen === "chombo" && (<GameScoringChombo gameState={gameState} controls={controls} navigate={setFlowScreen} />)}
    </div>
  );
}

const Game = () => {
  return (
    <div>
      <GameScoring />
    </div>);
};

const Experiments = () => {
  return (
    <div>
      No experiments available at the moment
    </div>);
};

function App() {
  // const navigationHash = (["#game", "#score", "#links", "#experiments"].includes(window.location.hash) ? window.location.hash : "#game").slice(1);
  const navigationHash = (["#game", "#score", "#achievements", "#links", "#experiments"].includes(window.location.hash) ? window.location.hash : "#game").slice(1);
  const [selectedView, setSelectedView] = useState(navigationHash);
  return (
    <div className="App">
        <nav className="d-none d-lg-block navbar navbar-light bg-green sticky-top" role="navigation">
            <a className="navbar-brand" href="#game">
                <img src="/favicon.ico" alt="favicon" />
                NetRonner
            </a>
        </nav>
        <div className="container" id="main-container">
          {selectedView === "game" && (<Game />)}
          {selectedView === "score" && (<Score />)}
          {/* {selectedView === "achievements" && (<Achievements />)} */}
          {selectedView === "links" && (<Links />)}
          {selectedView === "experiments" && (<Experiments />)}
        </div>
        <nav className="navbar navbar-light bg-green fixed-bottom nav-pills nav-fill" role="navigation">
            <a
              className={"nav-item nav-link game" + (selectedView === "game" ? " active": "")}
              href="#game"
              onClick={() => setSelectedView("game")}
              >
              <p>🀄</p>
              <p>Game score</p>
            </a>
            <a
              className={"nav-item nav-link score" + (selectedView === "score" ? " active": "")}
              href="#score"
              onClick={() => setSelectedView("score")}
              >
              <p>🧮</p>
              <p>Hand Score</p>
            </a>
            {/* <a
              className={"nav-item nav-link achievements" + (selectedView === "achievements" ? " active": "")}
              href="#achievements"
              onClick={() => setSelectedView("achievements")}
              >
              <p>🏆</p>
              <p>Achievements</p>
            </a> */}
            <a
              className={"nav-item nav-link links" + (selectedView === "links" ? " active": "")}
              href="#links"
              onClick={() => setSelectedView("links")}
              >
              <p>📖</p>
              <p>Resources</p>
            </a>
            <a
              className={"nav-item nav-link experiments" + (selectedView === "experiments" ? " active": "")}
              href="#experiments"
              onClick={() => setSelectedView("experiments")}
              >
              <p>🧪</p>
              <p>Experiments</p>
            </a>
        </nav>

    </div>
  );
}

export default App;
