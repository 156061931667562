import Winds, { WindValues, forName as windForName } from './Winds';
import Player from './Player';
import {
  init,
  last,
} from './Lists';
import {
  dispatch,
  newGame,
  GameData,
  GameEvent,
  GameEventType,
} from './GameData';
import { useState } from 'react';



type Players = [Player, Player, Player, Player];

type TsumoPayments = [/* east when winner is not east*/ number, number, number];

interface State {
  readonly isOver: boolean,
  readonly canUndo: boolean,
  readonly players: Players,
  readonly prevalentWind: Winds,
  readonly handNumber: number,
  readonly bonusCounter: number,
  readonly riichiBets: number,
};

interface Controls {
  ron(winningPlayersAndPayments: [Player, number][], discardingPlayer: Player): void,
  tsumo(winningPlayer: Player, payments: TsumoPayments): void,
  chombo(offendingPlayer: Player): void,
  draw(tenpaiPlayers: Player[]): void,
  riichi(declaringPlayer: Player): void,
  undo(): void,
  rename(player: Player, newName: string): void,
  newGame(keepNames: boolean): void,
};

type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>;

const initialWindOf = (data: GameData, player: Player): Winds => {
  const entry = Object.entries(data.players).find(([initialWind, entryPlayer]) => entryPlayer === player);
  return windForName(entry[0] as WindValues); // FIXME: force-casting because Object.entries extends the actual entries to string
};

const makeControls = (dataHistory: GameData[], setDataHistory: StateSetter<GameData[]>, isOver: boolean, setIsOver: StateSetter<boolean>): Controls => {
  const current = last(dataHistory);
  return {
    ron: (winningPlayersAndPayments: [Player, number][], discardingPlayer: Player): void => {
      if(isOver) {
        throw new Error("🛑 game is over, what are you even?");
      }
      if(winningPlayersAndPayments.length < 1) {
        throw new Error("Winning players must be at least 1");
      }
      if(winningPlayersAndPayments.length > 3) {
        throw new Error("🤨 how even");
      }
      const event: GameEvent = {
        type: GameEventType.Ron,
        winningPlayersAndPayments: winningPlayersAndPayments.map(([player, payment]) => [initialWindOf(current, player), payment]),
        discardingPlayer: initialWindOf(current, discardingPlayer),
      };
      const newGame = dispatch(current, event);
      const winningSeatWinds = winningPlayersAndPayments.map(([player, _]) => player.seatWind);
      const eastWin = (winningSeatWinds.includes(Winds.East));
      const isEndGame = (current.activeRound.prevalentWind === "south") && (current.activeRound.handNumber === 4) && !eastWin;
      setDataHistory([...dataHistory, newGame]);
      setIsOver(isEndGame);
    },
    tsumo: (winningPlayer: Player, payments: TsumoPayments): void => {
      if(isOver) {
        throw new Error("🛑 game is over, what are you even?");
      }
      const event: GameEvent = {
        type: GameEventType.Tsumo,
        winningPlayer: initialWindOf(current, winningPlayer),
        payments: payments,
      };
      const newGame = dispatch(current, event);
      const eastTsumo = winningPlayer.seatWind === Winds.East;
      const isEndGame = (current.activeRound.prevalentWind === "south") && (current.activeRound.handNumber === 4) && !eastTsumo;
      setDataHistory([...dataHistory, newGame]);
      setIsOver(isEndGame);
    },
    chombo: (offendingPlayer: Player): void => {
      if(isOver) {
        throw new Error("🛑 game is over, what are you even?");
      }
      const event: GameEvent = {
        type: GameEventType.Chombo,
        offendingPlayer: initialWindOf(current, offendingPlayer),
      }
      const newGame = dispatch(current, event);
      setDataHistory([...dataHistory, newGame]);
    },
    draw: (tenpaiPlayers: Player[]): void => {
      if(isOver) {
        throw new Error("🛑 game is over, what are you even?");
      }
      if(tenpaiPlayers.length > 4) {
        throw new Error("🤨 how even");
      }
      const event: GameEvent = {
        type: GameEventType.Draw,
        tenpaiPlayers: tenpaiPlayers.map(player => initialWindOf(current, player)),
      }
      const newGame = dispatch(current, event);
      const eastTenpai = tenpaiPlayers.map(player => player.seatWind).includes(Winds.East);
      const isEndGame = (current.activeRound.prevalentWind === "south") && (current.activeRound.handNumber === 4) && !eastTenpai;
      setDataHistory([...dataHistory, newGame]);
      setIsOver(isEndGame);
    },
    riichi: (declaringPlayer: Player): void => {
      if(isOver) {
        throw new Error("🛑 game is over, what are you even?");
      }
      const event: GameEvent = {
        type: GameEventType.Riichi,
        declaringPlayer: initialWindOf(current, declaringPlayer),
      }
      const newGame = dispatch(current, event);
      setDataHistory([...dataHistory, newGame]);
    },
    undo: (): void => {
      setDataHistory(init(dataHistory));
      if(isOver) {
        setIsOver(false);
      }
    },
    rename: (player: Player, newName: string): void => {
      // FIXME: this is not great, mapping across everything and "editing history" - player names likely need to become their own state?
      setDataHistory(dataHistory.map(data => {
        data.players[initialWindOf(current, player)].name = newName;
        return data;
      }))
    },
    newGame: (keepNames: boolean): void => {
      setIsOver(false);
      if(keepNames) {
        setDataHistory([newGame(current.players[Winds.East].name, current.players[Winds.South].name, current.players[Winds.West].name, current.players[Winds.North].name)]);
      } else {
        setDataHistory([newGame('First East', 'First South', 'First West', 'First North')]);
      }
    },
  }
}

const gameDataToState = (data: GameData, isOver: boolean, canUndo: boolean): State => {
  return {
    isOver,
    canUndo,
    players: [data.players[Winds.East], data.players[Winds.South], data.players[Winds.West], data.players[Winds.North]],
    prevalentWind: data.activeRound.prevalentWind,
    handNumber: data.activeRound.handNumber,
    bonusCounter: data.activeRound.bonusCounter,
    riichiBets: data.activeRound.riichiBets,
  };
}


const useGame = (existingGame?: [GameData[], boolean]): [State, Controls, [GameData[], boolean]] => {


  // I think newgame needs to be a control....
  // FIXME separate names in separate useState so it can be decoupled from internal representaiton of players (See above FIXME in rename...)
  const seed = existingGame ?? [[newGame('First East', 'First South', 'First West', 'First North')], false];
  const [historySeed, isOverSeed]: [GameData[], boolean] = seed;
  const [dataHistory, setDataHistory] = useState(historySeed);
  const [isOver, setIsOver] = useState(isOverSeed);

  return [gameDataToState(last(dataHistory), isOver, dataHistory.length > 1), makeControls(dataHistory, setDataHistory, isOver, setIsOver), [dataHistory, isOver]];
}

export {
  useGame,
};