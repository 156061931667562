type WindValues = "east" | "south" | "west" | "north";

enum Winds {
  East = "east",
  South = "south",
  West = "west",
  North = "north",
};

const forName = (wind: WindValues): Winds => {
    switch (wind) {
        case "east": return Winds.East;
        case "west": return Winds.West;
        case "south": return Winds.South;
        case "north": return Winds.North;
    }
}

export default Winds;
export type {
    WindValues
};
export {
    forName
};